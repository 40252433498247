<template>
  <div>
    <Header />
    <Loading v-if="isLoading" />

    <div class="logo w-1920">
      <div class="logopic w-1200">
        <div class="logopic-left">
          <img @click="home" src="../../assets/login/logo.gif" alt="" />
          <div class="zhuce">欢迎注册</div>
        </div>
        <div class="logopic-right">
          <div class="logopic-right_L">已有账号？</div>
          <router-link :to="{
              name: 'Login',
            }" tag="div" class="logopic-right_R">请登录></router-link>
        </div>
      </div>
    </div>
    <div class="conter w-1920">
      <div class="buzhou">
        <!-- <svg class="logo-search-progress">
          <image
            x="0"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="129"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-03.png"
          ></image>
          <image
            x="259"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-03.png"
          ></image>
          <image
            x="50"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-02.png"
          ></image>
          <image
            x="180"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-03.png"
          ></image>
          <image
            x="310"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-03.png"
          ></image>
          <text x="60" y="38" style="fill:#fff;">1</text>
          <text x="190" y="38" style="fill:#fff;">2</text>
          <text x="320" y="38" style="fill:#fff;">3</text>
          <text x="25" y="58" style="fill:#ff902a;">验证手机号</text>
          <text x="150" y="58" style="fill:#dedede;">填写账号信息</text>
          <text x="295" y="58" style="fill:#dedede;">注册成功</text>
        </svg> -->
      </div>
      <!-- <div class="input">
        <el-select v-model="select" slot="prepend" placeholder="中国  0086">
          <el-option label="中国  0086" value="1"></el-option>
          <el-option label="订单号" value="2"></el-option>
          <el-option label="用户电话" value="3"></el-option>
        </el-select>
        <el-input
          placeholder="建议使用常用手机号"
          v-model="input3"
          class="input-with-select"
          @blur="animate()"
        >
        </el-input>
      </div> -->

      <div class="root">
        <el-form :model="Register" ref="RegisterForm" label-width="0" class="RegisterForm">
          <el-form-item prop="phone">
            <el-input v-model="Register.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="Register.pass" placeholder="请输入密码" type="password" show-password></el-input>
          </el-form-item>
          <el-form-item prop="companyName">
            <el-input v-model="Register.companyName" placeholder="所属公司"></el-input>
          </el-form-item>
          <el-form-item prop="companyName">
            <div class="add">
              <div class="add_address" @click="address">
                编辑默认收货地址
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="验证码" class="code">
            <el-input v-model="Register.sendcode" placeholder="请输入验证码" @keyup.enter.native="submitForm"></el-input>
            <el-button type="button" @click="sendcode" :disabled="disabled" v-if="disabled == false">发送验证码
            </el-button>
            <el-button type="button" @click="sendcode" :disabled="disabled" v-if="disabled == true">{{ btntxt }}
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="success" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-dialog :title="articleTypeName" :visible.sync="centerDialogVisible" width="50%" :before-close="handleClose"
        center>
        <div class="el-dialog-div"><span v-html="content"></span></div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">同意并继续</el-button>
        </span>
      </el-dialog>

      <el-dialog title="默认收货地址" :visible.sync="dialogFormVisible" :before-close="handleClose2">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="收货人" :label-width="formLabelWidth" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" :label-width="formLabelWidth" prop="number">
            <el-input v-model="form.number" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="所在省" :label-width="formLabelWidth" prop="valueProvince">
            <!--  <el-input v-model="form.region" autocomplete="off"></el-input>-->
            <el-select style="width: 100%;" v-model="form.valueProvince" filterable placeholder="请选择省"
              @change="changeProvince">
              <el-option v-for="item in provinceList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在市" :label-width="formLabelWidth" prop="valueCity">
            <!--  <el-input v-model="form.shi" autocomplete="off"></el-input>-->
            <el-select style="width: 100%;" v-model="form.valueCity" filterable placeholder="请选择市" @change="changeCity">
              <el-option v-for="item in cityOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在区" :label-width="formLabelWidth" prop="valueOrigin">
            <!--  <el-input v-model="form.qu" autocomplete="off"></el-input>-->
            <el-select style="width: 100%;" v-model="form.valueOrigin" filterable placeholder="请选择区"
              @change="changeOrigin">
              <el-option v-for="item in originOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" :label-width="formLabelWidth" prop="xiangxi">
            <el-input type="textarea" v-model="form.xiangxi" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="固定电话" :label-width="formLabelWidth" prop="dianhua">
            <el-input v-model="form.dianhua" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邮政编码" :label-width="formLabelWidth" prop="email">
            <el-input v-model="form.email" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{ $t('button.Cancel') }}</el-button>
          <el-button type="primary" @click="submitAddressForm1('form')">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 滑块验证，暂时清除 -->
      <!-- <div class="yanzheng">
        <el-form
          class="form-wrapper"
          ref="myForm"
          :model="myForm"
          label-width="110px"
        >
          <el-form-item>
            <div><slider ref="slider"></slider></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="toSubmit">下一步</el-button>
          </el-form-item>
        </el-form>
      </div> -->
    </div>
    <Login_bottom />
    <el-button :plain="true" v-show="false"></el-button>
  </div>
</template>
<script>
  import {
    get,
    post
  } from '@/utils/request';
  import Header from '@/components/header.vue';
  import Login_bottom from '@/components/login/login_bottom.vue';
  import slider from '@/components/login/verify.vue';
  import {
    chinaAddress
  } from '../../utils/china_address.js';
  export default {
    components: {
      Login_bottom,
      Header,
      slider,
    },
    data() {
      return {
        //18697560871
        Register: {
          phone: '',
          companyName: '',
          sendcode: '',
          pass: '',
        },
        disabled: false,
        time: 0,
        btntxt: '重新发送',

        input3: '',
        select: '',
        myForm: {},
        isLoading: false,
        centerDialogVisible: true,
        content: '',
        articleTypeName: '',

        dialogFormVisible: false,
        form: {
          name: '',
          regionId: '',
          region: '',
          xiangxi: '',
          number: '',
          dianhua: '',
          email: '',
          bieming: '',
          shiId: '',
          shi: '',
          quId: '',
          qu: '',
          valueProvince: '',
          valueCity: '',
          valueOrigin: ''
        },
        rules: {
          name: [{
              required: true,
              message: '请输入收货人姓名',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 5,
              message: '长度在 1 到 5 个字符',
              trigger: 'blur'
            },
          ],
          valueProvince: [{
            required: true,
            message: '请选择省',
            trigger: 'change'
          }],
          valueCity: [{
            required: true,
            message: '请选择市',
            trigger: 'change'
          }],
          valueOrigin: [{
            required: true,
            message: '请选择区',
            trigger: 'change'
          }],
          xiangxi: [{
              required: true,
              message: '请输入详细地址',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 50,
              message: '长度在 3 到 50 个字符',
              trigger: 'blur',
            },
          ],
          number: [{
              required: true,
              message: '请输入手机号码',
              trigger: 'blur'
            },
            {
              min: 11,
              max: 11,
              message: '长度为11个字符',
              trigger: 'blur'
            },
          ],
        },
        provinceList: [], // 省列表
        cityList: [], // 市列表
        originList: [], // 区列表
        valueProvince: '', // 所选的省Id
        nameProvince: '', // 所选的省
        valueCity: '', // 所选的市Id
        nameCity: '', // 所选的市
        valueOrigin: '', // 所选的区Id
        nameOrigin: '', // 所选的区
        cityOptions: [], // 市下拉框数据
        originOptions: [], // 区下拉框数据
      };
    },
    created() {
      this.selectContent();
      this._getJsonData();
    },
    methods: {
      //用户协议
      selectContent() {
        get('f/api/articleType/selectById?id=7').then((res) => {
          this.articleTypeName = res.data.data.articleTypeName;
          this.content = res.data.data.content;
        });
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            this.$router.go(-1);
          })
          .catch((_) => {});
      },
      handleClose2(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      home() {
        this.$router.push({
          name: 'Home',
        });
      },
      //手机验证发送验证码
      sendcode() {
        this.isLoading = true;
        const reg = 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/;
        if (this.Register.phone == '') {
          this.isLoading = false;
          this.$message.warning({
            message: '手机号不能为空',
          });
          return;
        }
        if (!reg.test(this.Register.phone)) {
          this.isLoading = false;
          this.$message.warning({
            message: '请输入正确的手机号',
          });
          return;
        } else {
          get('f/api/register/registerPhone?phone=' + this.Register.phone).then(
            (res) => {
              this.isLoading = false;
              if (res.data.msg == '短信发送成功') {
                this.$message.success({
                  message: '发送成功',
                });
                this.time = 60;
                this.disabled = true;
                this.timer();
              } else if (res.data.msg == '账号已存在') {
                this.$message.warning({
                  message: '账号已存在',
                });
              } else {
                this.$message.warning({
                  message: '发送失败',
                });
              }
            }
          );
        }
      },
      //60S倒计时
      timer() {
        if (this.time > 0) {
          this.time--;
          this.btntxt = this.time + 's后重新获取';
          setTimeout(this.timer, 1000);
        } else {
          this.time = 0;
          this.btntxt = '获取验证码';
          this.disabled = false;
        }
      },
      //提交
      submitForm() {
        this.form.regionId=this.valueProvince;
        this.form.region=this.nameProvince;
        this.form.shiId=this.valueCity;
        this.form.shi=this.nameCity;
        this.form.quId=this.valueOrigin;
        this.form.qu=this.nameOrigin;

        this.isLoading = true;
        const reg1 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
        if (this.form.name == '') {
          this.$message.warning({
            message: '必须维护默认收货地址信息',
          });
          this.isLoading = false;
          return false;
        }
        if (!reg1.test(this.Register.pass)) {
          this.isLoading = false;
          this.$message.warning({
            message: '密码须包含数字、字母两种元素，且密码位数为6-16位',
          });
          return;
        } else if (
          this.Register.phone &&
          this.Register.sendcode &&
          this.Register.pass && this.Register.companyName
        ) {
          this.$refs['form'].validate((valid) => {
            if (!valid) {
              this.$message.warning({
                message: '必须维护默认收货地址信息',
              });
              this.isLoading = false;
              return false;
            } else {
              post('f/api/register/register', {
                phone: this.Register.phone,
                code: this.Register.sendcode,
                password: this.Register.pass,
                companyName:this.Register.companyName, 
                address:{
                  receiverName: this.form.name,
                  receiverPhone: this.form.number,
                  receiverProvinceId: this.form.regionId,
                  receiverProvince: this.form.region,
                  receiverCityId: this.form.shiId,
                  receiverCity: this.form.shi,
                  receiverDistrictId: this.form.quId,
                  receiverDistrict: this.form.qu,
                  receiverAddress: this.form.xiangxi,
                  receiverFixedTelephone: this.form.dianhua,
                  postcode: this.form.email,
                }
              }).then((res) => {
                this.isLoading = false;
                if (res.data.msg == '操作成功') {
                  this.$router.push({
                    name: 'Person_detail',
                    params: {
                      // 用于下一页面查找会员信息
                      phone: this.Register.phone
                    }
                  });
                } else {
                  this.$message.error({
                    message: '注册失败',
                  });
                }
              });

            }
          });
        } else {
          this.isLoading = false;
          this.$message.warning({
            message: '请填写完整',
          });
        }
        // this.$router.push({
        //   name: 'Person_detail',
        // });
      },

      address() {
        this.dialogFormVisible = true;
      },
      // 选择省
      changeProvince(val) {
        this.provinceList.forEach((province, index) => {
          if (val.toString() === this.provinceList[index].value) {
            this.valueProvince = this.provinceList[index].value;
            this.nameProvince = this.provinceList[index].label;
            this.cityOptions = this.provinceList[index].children;
            this.originOptions = this.provinceList[index].children[0].children;
            this.valueCity = '';
            this.valueOrigin = '';
            // 如果选中省之后想市区默认选中
            // this.valueCity = this.provinceList[index].children[0].value;
            // this.nameCity = this.provinceList[index].children[0].label;
            // this.valueOrigin = this.provinceList[index].children[0].children[0].value;
            // this.nameOrigin=this.provinceList[index].children[0].children[0].label;
          }
        })
      },
      // 选择市
      changeCity(val) {
        this.cityList.forEach((city, index) => {
          if (val.toString() === this.cityList[index].value) {
            this.valueCity = this.cityList[index].value;
            this.nameCity = this.cityList[index].label;
            this.originOptions = this.cityList[index].children;
            this.valueOrigin = '';
            //如果选中市之后默认区选中
            // this.valueOrigin = this.cityList[index].children[0].value;
            // this.nameOrigin=this.cityList[index].children[0].label;
          }
        })
      },
      // 选择区
      changeOrigin(val) {
        this.originList.forEach((origin, index) => {
          if (val.toString() === this.originList[index].value) {
            this.valueOrigin = this.originList[index].value;
            this.nameOrigin = this.originList[index].label;
          }
        })
      },
      // 初始化省市区数据
      _getJsonData() {
        this.provinceList = []
        this.cityList = []
        this.originList = []
        chinaAddress().forEach((item) => {
          if (item.value.match(/0000$/)) {
            this.provinceList.push({
              value: item.value,
              label: item.name,
              children: []
            })
          } else if (item.value.match(/00$/)) {
            this.cityList.push({
              value: item.value,
              label: item.name,
              children: []
            })
          } else {
            this.originList.push({
              value: item.value,
              label: item.name
            })
          }
        })
        for (let index in this.provinceList) {
          for (let index1 in this.cityList) {
            if (this.provinceList[index].value.slice(0, 2) === this.cityList[index1].value.slice(0, 2)) {
              this.provinceList[index].children.push(this.cityList[index1])
            }
          }
        }
        for (let item1 in this.cityList) {
          for (let item2 in this.originList) {
            if (this.originList[item2].value.slice(0, 4) === this.cityList[item1].value.slice(0, 4)) {
              this.cityList[item1].children.push(this.originList[item2])
            }
          }
        }
      },
      submitAddressForm1(formName) {
        this.form.regionId = this.valueProvince;
        this.form.region = this.nameProvince;
        this.form.shiId = this.valueCity;
        this.form.shi = this.nameCity;
        this.form.quId = this.valueOrigin;
        this.form.qu = this.nameOrigin;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.dialogFormVisible = false;
          }
        });
      },

    },

  };
</script>
<style lang="scss" scoped>
  .el-dialog-div {
    height: 400px;
    overflow: auto;
  }

  .root {
    background: url('http://img.hena360.cn/login_bj.jpg') no-repeat center;
    background-size: 100% 100%;
    height: 100px;
    width: 440px;
    margin: auto;
    margin-top: 160px;

    form {
      width: 90%;
      margin: auto;

      .code {
        .el-input {
          width: 65%;
          border-radius: 0px;
          float: left;
          display: inline-block;
        }

        .el-button {
          width: 35%;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px;
          float: left;
          display: inline-block;
        }
      }

      .el-button--success {
        width: 100%;
        background-color: #245af2;
        border: none;
        color: #fff;
      }
    }
  }

  .form-wrapper {
    margin-top: 50px;
    width: 500px;
    margin-left: 0;
  }

  .logo {
    width: 100%;
    height: 110px;
    border-bottom: 3px solid #f5f5f5;

    .logopic {
      width: 1234px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;

        img {
          width: 158px;
          height: 44px;
          margin-left: 33px;
          cursor: pointer;
        }

        .zhuce {
          font-size: 20px;
          color: #245af2;
          font-weight: 600;
          margin-left: 27px;
        }
      }

      &-right {
        height: 100px;
        font-size: 15px;
        display: flex;
        align-items: flex-end;

        &_L {
          color: #9a9a9a;
        }

        &_R {
          color: #f14f44;
          margin-left: 17px;
          cursor: pointer;
        }
      }
    }
  }

  .conter {
    padding-bottom: 273px;
    width: 100%;
    height: 255px;

    .buzhou {
      width: 390px;
      margin: auto;
      margin-top: 60px;

      .logo-search-progress {
        width: 100%;
        height: 104px;
      }
    }

    .input {
      width: 612px;
      height: 100%;
      margin: auto;
      margin-top: 90px;
      display: flex;

      .el-select {
        width: 250px;
      }
    }

    .yanzheng {
      width: 612px;
      margin: auto;
      text-align: center;
      cursor: pointer;
    }
  }

  .add {
    height: 35px;
    display: flex;
    align-items: center;

    .add_address {
      width: 137px;
      height: 100%;
      border: 1px solid #1750ff;
      color: #1750ff;
      border-radius: 5px;
      line-height: 35px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
    }

    .created {
      font-size: 12px;
      color: #797979;
      margin-left: 20px;

      .color {
        color: #ff902a;
      }
    }
  }
</style>